;(function (angular) {
   'use strict'

   angular.module('internalWebsiteApp').controller('LogisticsCtrl', logisticsController)

   function logisticsController(
      $q,
      $scope,
      $location,
      $uibModal,
      $window,
      $routeParams,
      $http,
      logisticsData,
      util,
      alertService,
      ENV,
   ) {
      var _logisticsRootState = {
         busy: false,
         busyString: undefined,
         shouldPersistStopChanges: true, // Persist stop changes by default (note that non-future trip changes are never persisted)
         loadingRoutes: true,
         hideInactiveRoutes: true,
         allowChildViewsToLoad: false,
         params: $routeParams,
         routesAll: undefined,
         routesActive: undefined,
         routesInactive: undefined,
         route: undefined, // the currently loaded route
         person: undefined,
         truckDriversList: undefined,
         truckDriversObj: undefined,
         statusOfToggledOpenTrip: undefined,
         totalUnsavedTripEditsCount: 0,
         totalUnsavedStopEditsCount: 0,
         currentState: {},
         updateParams: function (paramsObj) {
            var updateTo = angular.extend({}, _logisticsRootState.params, paramsObj)
            $location.search(updateTo)
            return $q.resolve()
         },
         formatRouteDescription: function (description) {
            // add a zerowidth space after slashes to allow text wrapping after the slashes
            return description.replace(/\//g, '/\u200B')
         },
      }

      $scope.logisticsRootState = _logisticsRootState

      //================================================================================
      // Select UI State and handlers (for lists of drops)
      //================================================================================

      var _selectUiState = {
         // Note: `selectableList` is set in the view's `ng-repeat` to ensure that we're
         // selecting the filtered and sorted list with which the user is interacting.
         selectedList: [],
         selectedPickups: [],
         // Note: We have `selectedPickups` for the "change truck" UI (changing trucks is only supported
         // for pickups, not drops). So far, we don't have a need for `selectedDrops`.
         allVisibleSelected: false,
      }
      $scope.selectUiState = _selectUiState

      var _selectUiHandlers = {
         // Single
         selectItem: function (item) {
            var itemAlreadySelected = _selectUiState.selectedList.some(function (selectedItem) {
               return selectedItem.id === item.id
            })

            if (!itemAlreadySelected) {
               _selectUiState.selectedList.push(item)

               if (item.pickupId) {
                  _selectUiState.selectedPickups.push(item)
               }

               // Ensure that the select state is set (necessary when calling this programmatically)
               item.selectedInUi = true
            }
         },
         unselectItem: function (item) {
            util.removeById(_selectUiState.selectedList, item.id)
            if (item.pickupId) {
               util.removeById(_selectUiState.selectedPickups, item.id)
            }

            // Ensure that the "all" checkbox is unchecked
            _selectUiState.allVisibleSelected = false

            // Ensure that the select state is set (necessary when calling this programmatically)
            item.selectedInUi = false
         },
         handleSelectChange: function (item) {
            if (item.selectedInUi) {
               this.selectItem(item)
            } else {
               this.unselectItem(item)
            }
         },
         // All
         selectAll: function () {
            _selectUiState.selectableList.forEach(function (item) {
               item.selectedInUi = true
            })
            _selectUiState.selectedList = angular.copy(_selectUiState.selectableList)

            _selectUiState.selectedPickups = _selectUiState.selectedList.filter(function (dropOrPickup) {
               return dropOrPickup.pickupId
            })

            // Ensure that the select all checkbox is checked (necessary when calling this programmatically)
            _selectUiState.allVisibleSelected = true
         },
         unselectAll: function () {
            _selectUiState.selectedList = []
            _selectUiState.selectedPickups = []
            if (_selectUiState.selectableList && _selectUiState.selectableList.length) {
               _selectUiState.selectableList.forEach(function (item) {
                  item.selectedInUi = false
               })
            }

            // Ensure that the select all checkbox is unchecked (necessary when calling this programmatically)
            _selectUiState.allVisibleSelected = false
         },
         handleSelectAllChange: function () {
            if (_selectUiState.allVisibleSelected) {
               this.selectAll()
            } else {
               this.unselectAll()
            }
         },
      }
      $scope.selectUiHandlers = _selectUiHandlers

      //================================================================================
      // Actions for selected stops/drops
      //================================================================================

      function getSelectedList() {
         if (_logisticsRootState.params.tab === 'drops') {
            return _selectUiState.selectedList
         } else {
            var currentTrip = util.findById(_logisticsRootState.route.trips, parseInt(_logisticsRootState.params.trip))
            return currentTrip.selectedStops
         }
      }

      $scope.openSelectedDrops = function () {
         var urls = getSelectedList().map(function (item) {
            if (item.drop) {
               return makeBeehiveDropUrl(item.drop.id)
            } else if (item.pickup) {
               return makeBeehivePickupUrl(item.pickup.vendors[0])
            }
            // Selected item is a drop in the drop tab (`item` is the drop object, it's not nested)
            else {
               return makeBeehiveDropUrl(item.id)
            }
         })
         openUrls(urls)
      }

      $scope.openSelectedDCs = function () {
         var urls = getSelectedList().map(function (item) {
            // Note: We're using the "quick jump" route here because the beehive route for customer
            // uses the Beehive `personId` but the API only gives the customer number.
            if (item.drop) {
               return makeBeehiveCustomerUrl(item.drop.coordinators[0])
            } else if (item.pickup) {
               return makeBeehivePickupContactUrl(item.pickup.vendors[0])
            }
            // Selected item is a drop in the drop tab (`item` is the drop object, it's not nested)
            else {
               return makeBeehiveCustomerUrl(item.coordinators[0])
            }
         })
         openUrls(urls)
      }

      $scope.openMessageModal = function (dropId) {
         $scope.$uibModalInstance = $uibModal.open({
            templateUrl: 'modules/logistics/views/modal.sendMessage.html',
            size: 'md',
            controller: 'ModalSendMessage',
            backdrop: 'static',
            resolve: {
               dropsToMessage: function () {
                  if (dropId !== undefined) {
                     return [{id: dropId}]
                  } else if (_logisticsRootState.params.tab === 'drops') {
                     return _selectUiState.selectedList
                  } else if (_logisticsRootState.params.tab === 'trips') {
                     var currentTrip = util.findById(
                        _logisticsRootState.route.trips,
                        parseInt(_logisticsRootState.params.trip),
                     )
                     var stopsToMessage = currentTrip.selectedStops.map(function (stop) {
                        // Because both customer drops and vendor pickups are listed in the stops list,
                        // we need to return whichever one is set.
                        let dropOrPickup = stop.drop || stop.pickup
                        dropOrPickup.finalizedDelivery = stop.finalizedDelivery
                        dropOrPickup.finalizedDeliveryFlag = stop.finalizedDeliveryFlag
                        return dropOrPickup
                     })
                     return stopsToMessage
                  }
               },
               logisticsRootState: function () {
                  return _logisticsRootState
               },
               isBulkMoveOrdersMode: function () {
                  return dropId !== undefined
               },
            },
         })
         return $scope.$uibModalInstance
      }

      $scope.changeRoute = function () {
         $scope.$uibModalInstance = $uibModal.open({
            templateUrl: 'modules/logistics/views/modal.changeDropRoute.html',
            size: 'md',
            resolve: {
               selectedDrops: function () {
                  if (_logisticsRootState.params.tab === 'drops') {
                     return _selectUiState.selectedList
                  }
               },
               logisticsRootState: function () {
                  return _logisticsRootState
               },
            },
            controller: function (selectedDrops, logisticsRootState, $scope) {
               var _localState = {
                  step: 1,
                  selectedDrops: selectedDrops,
                  selectedDropIds: selectedDrops.map(function (drop) {
                     return drop.id
                  }),
                  moveToRoute: undefined,
               }

               $scope.localState = _localState
               $scope.logisticsRootState = logisticsRootState
               $scope.routes = logisticsRootState.routesAll.filter(function (route) {
                  return route.id !== logisticsRootState.route.id
               })

               $scope.submit = function () {
                  _localState.busy = true
                  return $http
                     .post(
                        ENV.apiEndpoint + '/drops/actions/convert-route',
                        {
                           sourceRouteId: logisticsRootState.route.id,
                           targetRouteId: _localState.moveToRoute.id,
                           dropIds: _localState.selectedDropIds,
                        },
                        {
                           withCredentials: true,
                        },
                     )
                     .then(function () {
                        // The currently selected trips remain on the drop they're moved from for preserving
                        // stop history (at least some type of relationship remains). It was discussed to somehow
                        // indicate that they're only there for historical reasons but decided against doing for now.
                        _selectUiHandlers.unselectAll()

                        return logisticsData
                           .getRoutesEarliestFutureTrip(_localState.moveToRoute.name)
                           .then(function (earliestFutureTrip) {
                              _localState.step = 2
                              $scope.earliestFutureTrip = earliestFutureTrip
                           })
                           .finally(function () {
                              _localState.busy = false
                           })
                     })
                     .catch(function (errorResponse) {
                        _localState.step = 3
                        _localState.errorData = errorResponse.data.error
                     })
                     .finally(function () {
                        _localState.busy = false
                     })
               }
            },
         })
      }

      function openUrls(urls) {
         urls.every(function (url) {
            var newTab = $window.open(url, '_blank')

            // If the popup opens successfully, continue.
            if (newTab) {
               return true
            }
            // Else, show an error message and exit the iteration.
            else {
               $window.alert('Please allow popups for the site in order to use this feature.')

               return false
            }
         })
      }

      function makeBeehiveCustomerUrl(customerId) {
         return ENV.beehive + '/beekeeper/quick-jump?quick_jump=C' + customerId + '&submit=View'
      }
      $scope.makeBeehiveCustomerUrl = makeBeehiveCustomerUrl

      function makeBeehivePickupContactUrl(vendorId) {
         return makeBeehivePickupUrl(vendorId) + '/contacts'
      }
      $scope.makeBeehivePickupContactUrl = makeBeehivePickupContactUrl

      function makeBeehiveDropUrl(dropId) {
         return ENV.beehive + '/drops/' + dropId + '/detail'
      }
      $scope.makeBeehiveDropUrl = makeBeehiveDropUrl

      function makeBeehivePickupUrl(vendorId) {
         return ENV.beehive + '/vendor/' + vendorId
      }
      $scope.makeBeehivePickupUrl = makeBeehivePickupUrl

      //================================================================================
      // Internal functions
      //================================================================================

      function loadRoutes() {
         return logisticsData.getAllRoutes().then(function (routes) {
            _logisticsRootState.routesAll = routes
            _logisticsRootState.routesActive = routes.filter(function (route) {
               return route.isActive
            })
            _logisticsRootState.routesInactive = routes.filter(function (route) {
               return !route.isActive
            })

            routes.forEach(function (route) {
               route.description = _logisticsRootState.formatRouteDescription(route.description)
            })

            _logisticsRootState.route = _logisticsRootState.params.route
               ? util.findById(routes, _logisticsRootState.params.route)
               : routes[0]

            _logisticsRootState.loadingRoutes = false
         })
      }

      _logisticsRootState.loadRoutes = loadRoutes

      function loadTruckCarrierPeople() {
         return logisticsData.getTruckloadRelatedPeople().then(function (truckloadPeople) {
            function filterByCustomerType(type) {
               return truckloadPeople.filter(function (person) {
                  return person.azureTypes && person.azureTypes.includes(type)
               })
            }

            _logisticsRootState.truckloadPeopleAll = truckloadPeople

            _logisticsRootState.truckloadPeopleByType = {
               truckDrivers: filterByCustomerType('truckDriver'),
               carrierContractors: filterByCustomerType('carrierContractor'),
               carrierShuttles: filterByCustomerType('carrierShuttle'),
            }

            _logisticsRootState.truckDriversObj = _logisticsRootState.truckloadPeopleByType.truckDrivers.reduce(
               (obj, driver) => Object.assign(obj, {[driver.id]: driver}),
               {},
            )
         })
      }

      function resetEditCounts() {
         _logisticsRootState.totalUnsavedStopEditsCount = 0
         _logisticsRootState.totalUnsavedTripEditsCount = 0
      }

      //================================================================================
      // Scope helpers
      //================================================================================

      $scope.scrollToCurrentRoute = function () {
         if (!_logisticsRootState.params) {
            return
         }
         $window.setTimeout(function () {
            var currentRoute = document.getElementById('js-route-' + _logisticsRootState.params.route)
            return currentRoute && currentRoute.scrollIntoView()
         })
      }

      $scope.hasPermission = function (permission) {
         return (
            _logisticsRootState.person.permissions && _logisticsRootState.person.permissions.indexOf(permission) > -1
         )
      }

      //================================================================================
      // logistics root scope Drop Data loading
      //================================================================================

      _logisticsRootState.loadRouteDrops = function (routeId, forceReload) {
         // Do not load drops if they've already been loaded.
         var loadIntoRoute = util.findById(_logisticsRootState.routesAll, routeId)
         if (loadIntoRoute && loadIntoRoute.drops && loadIntoRoute.drops.length && !forceReload) {
            loadIntoRoute.busy = false
            return $q.resolve()
         }

         // Drops not yet loaded... load them.

         function createDropView(drop) {
            drop.selectedInUi = false
            drop.lastDeparture = null
            drop['order-frequency'].forEach(function (order) {
               if (order.orders > 0) {
                  drop.lastDeparture = order.cutoff
               }
            })
            return drop
         }

         function getShippingFee() {
            if (_logisticsRootState.route.trips && _logisticsRootState.route.trips[0].earliestDrop) {
               return _logisticsRootState.route.trips[0].earliestDrop['drop-shipping-fee']
            } else {
               return logisticsData.getFurthestOutTrip(loadIntoRoute.name).then(function (trip) {
                  if (!trip) {
                     return
                  }
                  return logisticsData.getTripsEarliestDrop(trip.id).then(function (earliestDrop) {
                     return earliestDrop && earliestDrop['drop-shipping-fee']
                  })
               })
            }
         }

         loadIntoRoute.busy = true
         loadIntoRoute.drops = []

         // Load inactive drops in the background
         // We could alternatively load them on demand... requiring the user to click a button
         // for them to load. Without better insight on usage it's unclear which approach is best.
         loadIntoRoute.inactiveDropsLoaded = false
         logisticsData
            .getAllRouteDropsInactive(loadIntoRoute)
            .then(function (inactiveDrops) {
               if (!inactiveDrops.length) {
                  return $q.resolve()
               }
               var inactiveDropViews = inactiveDrops.map(createDropView)
               loadIntoRoute.dropsInactiveCount = inactiveDropViews.length
               Array.prototype.push.apply(loadIntoRoute.drops, inactiveDropViews)
            })
            .catch(function (error) {
               console.error(error)
               alertService.errorMessage('Error loading inactive route drops. Error logged to console.')
            })
            .finally(function () {
               loadIntoRoute.inactiveDropsLoaded = true
            })

         var promises = [logisticsData.getAllRouteDropsActive(loadIntoRoute), getShippingFee()]

         return $q
            .all(promises)
            .then(function (results) {
               var drops = results[0]
               loadIntoRoute.dropShippingFee = results[1]

               loadIntoRoute.dropsActiveIds = drops.map(function (drop) {
                  return drop.id
               })
               loadIntoRoute.dropsActive = drops.map(createDropView)
               Array.prototype.push.apply(loadIntoRoute.drops, loadIntoRoute.dropsActive)

               return loadIntoRoute.drops
            })
            .catch(function (error) {
               console.error(error)
               alertService.errorMessage('Error loading route drops. Error logged to console.')
            })
            .finally(function () {
               loadIntoRoute.busy = false
            })
      }

      //================================================================================

      function setRouteViaParams(params) {
         _logisticsRootState.route =
            params && params.route
               ? util.findById(_logisticsRootState.routesAll, params.route)
               : _logisticsRootState.routesAll[0]
      }

      function sharedParamsChangeHandler(toParams, fromParams) {
         if (!toParams) {
            return
         }

         if (toParams.tab === 'drops') {
            _logisticsRootState.loadRouteDrops(_logisticsRootState.route.id)
         }

         $scope.$broadcast('paramsChanged', toParams, fromParams)

         // Ensure the current route is scrolled to
         $scope.scrollToCurrentRoute()
      }

      //================================================================================
      // Initialization
      //================================================================================

      function handleParamsOnInit(params) {
         // Make the trips tab default
         if (!params.tab) {
            _logisticsRootState.params.tab = 'trips'
         }

         if (!params.route || !_logisticsRootState.route) {
            setRouteViaParams(params)
            _logisticsRootState.params.route = _logisticsRootState.route.id
         }

         // Ensure that any other params are passed through (such as `filterAfter` and `filterBefore`)
         _logisticsRootState.params = angular.extend({}, _logisticsRootState.params, params)
         _logisticsRootState.updateParams(_logisticsRootState.params)

         sharedParamsChangeHandler(params)
      }

      function init() {
         return logisticsData
            .getCurrentUser()
            .then(function (currentUser) {
               _logisticsRootState.person = currentUser
            })
            .then(loadTruckCarrierPeople)
            .then(loadRoutes)
            .then(function () {
               $routeParams.route = $routeParams.route && parseInt($routeParams.route)
               $routeParams.trip = $routeParams.trip && parseInt($routeParams.trip)
               handleParamsOnInit($routeParams)
               _logisticsRootState.allowChildViewsToLoad = true
            })
      }

      init()

      //================================================================================
      // State changing via `$locationChangeSuccess` (so back and forward buttons work)
      //================================================================================

      function handleParamsOnLocationChange(toParams, fromParams) {
         _logisticsRootState.params = toParams
         setRouteViaParams(toParams)
         sharedParamsChangeHandler(toParams, fromParams)
      }

      function promptIfUnsavedChanges(event) {
         if (_logisticsRootState.totalUnsavedTripEditsCount || _logisticsRootState.totalUnsavedStopEditsCount) {
            return $q(function (resolve, reject) {
               var confirmed = window.confirm('You have unsaved changes that will be lost. Continue anyway?')
               if (confirmed) {
                  return resolve()
               } else {
                  if (event) {
                     event.preventDefault()
                  }
                  return reject()
               }
            })
         } else {
            return $q.resolve()
         }
      }

      $scope.$on('$locationChangeStart', function (event) {
         // Exit the location change if the user opted to not lose changes
         return promptIfUnsavedChanges(event)
      })

      $scope.$on('$locationChangeSuccess', function (event, toUrl, fromUrl) {
         // Note: For `toParams`, we could use `$routeParams` but to ensure that there are no discrepancies
         // between `to` and `from` params it seems better to use the same util function for both. For example, I noticed
         // that `$routeParams` casts number params as type `number` whereas the util function casts them as type `string`... this
         // wouldn't break anything at the moment but could potentially introduce hard-to-find bugs in the future).
         var toParams = util.getParamsFromUrlString(toUrl)
         var fromParams = util.getParamsFromUrlString(fromUrl)
         // Convert string IDs into `int`s (necessary since the IDs are returned as `int`s from the API)
         if (toParams) {
            toParams.route = toParams.route && parseInt(toParams.route)
            toParams.trip = toParams.trip && parseInt(toParams.trip)
         }
         if (fromParams) {
            fromParams.route = fromParams.route && parseInt(fromParams.route)
            fromParams.trip = fromParams.trip && parseInt(fromParams.trip)
         }
         resetEditCounts()
         handleParamsOnLocationChange(toParams, fromParams)
      })

      $scope.$on('$destroy', function () {
         // Unset the `_logisticsRootState` to help prevent any hard-to-find memory leaks and such
         _logisticsRootState = null
      })
   }
})(angular)
