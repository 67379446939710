;(function () {
   'use strict'

   angular
      .module('internalWebsiteApp')
      .controller(
         'CategoriesCtrl',
         function ($scope, $timeout, $window, AzureAPI, alert, config, slug, usSpinnerService) {
            var _categoryTreeScrollContainer = document.getElementById('js-categoryTreeScrollContainer')

            //-- Scope Variables ------------------------------------------------------
            $scope.searchTerm = '' // the term to search for.
            $scope.productQueryLimit = 250 // the maximum number of search results to return from the server
            $scope.spinnerOptions = {
               // the options for the loading spinner during a search request
               radius: 5,
               width: 2,
               length: 5,
            }
            $scope.spinnerOptionsButton = {
               // the options for the loading spinner while loading uncategorized items
               radius: 2,
               width: 3,
               length: 2,
               color: '#ffe4b2',
            }
            $scope.status = ''

            $scope.tree = [] // holds the category tree
            $scope.depthErrorMsg = 'This Category is more than five levels deep and will not display on the website.'

            $scope.uncategorized = [] // holds the items retrieved from the loadUncategoried requests
            $scope.loadingUncategorized = false

            $scope.primaryCategoryAudit = [] // holds items retrieved from the loadPrimaryAudit requests

            $scope.resultsSortKey = '' // the field that the search results was last sorted by
            $scope.sortAsc = true // the direction of the current sort

            $scope.pageSize = 25 // the number of search results to display on each page
            $scope.currentPage = 1 // the currently displayed page for the search results
            $scope.totalPages = 0 // the number of pages of search results
            $scope.displayItems = [] // all the search results (for every page).  Changes between uncategorized and search-results.
            $scope.displayPage = [] // search results for th current page

            $scope.categoryToAdd = {} // holds the category to add
            $scope.categoryToEdit = {} // holds the category to edit
            //-- end of Scope Variables -----------------------------------------------

            //-- master list of items -------------------------------------------------
            var items = {}
            //-- end of master list of items ------------------------------------------

            //-- Uncategorized Items --------------------------------------------------
            $scope.showUncategorized = function () {
               $scope.displayItems = $scope.uncategorized
               $scope.setPage(1)
            }

            $scope.getUncategorized = function (start) {
               // get the uncategorized items.
               start = start || 0
               var limit = 250
               $scope.loadingUncategorized = true
               usSpinnerService.spin('spinner-uncategorized')

               return AzureAPI['packaged-product']
                  .query({
                     category: 'null',
                     limit: limit,
                     start: start,
                     tag: '*new-not-on-sale',
                     inline: 'product.brand',
                  })
                  .$promise.then(function (resp) {
                     // retrieve all uncategorized packaged-products in batches of 250 (the api max)
                     if (resp.length === limit) {
                        $scope.getUncategorized(start + limit)
                     } else {
                        $scope.loadingUncategorized = false
                        usSpinnerService.stop('spinner-uncategorized')
                     }

                     // add the categories and push each packaged-product into the uncategorized array.
                     processPackagedProducts(resp, $scope.uncategorized, true)
                  })
                  .catch(function (err) {
                     console.log('uncategorized error: ', err)
                     alert.add('danger', 'There was a problem loading the uncategorized products.')
                     usSpinnerService.stop('spinner-uncategorized')
                  })
            }
            //-- end of Uncategorized Items -------------------------------------------

            //-- Primary Category Item Audit ------------------------------------------
            $scope.showPrimaryAudit = function () {
               $scope.displayItems = $scope.primaryCategoryAudit
               $scope.setPage(1)
            }

            $scope.loadPrimaryAudit = function (start) {
               start = start || 0
               var limit = 250
               $scope.loadingMissingPrimary = true
               usSpinnerService.spin('spinner-primary')
               //var results = [];
               //$scope.displayItems = [];
               AzureAPI['packaged-product']
                  .query({
                     'primary-category': 'null',
                     limit: limit,
                     start: start,
                     tag: '*new-not-on-sale',
                     inline: 'product.brand',
                  })
                  .$promise.then(function (resp) {
                     // retrieve all uncategorized packaged-products in batches of 250 (the api max)
                     if (resp.length === limit) {
                        $scope.loadPrimaryAudit(start + limit)
                     } else {
                        $scope.loadingMissingPrimary = false
                        usSpinnerService.stop('spinner-primary')
                     }
                     processPackagedProducts(resp, $scope.primaryCategoryAudit)
                  })
                  .catch(function (err) {
                     console.log('primary category audit error: ', err)
                     alert.add('danger', 'There was a problem loading the products without a primary category.')
                     usSpinnerService.stop('spinner-primary')
                  })
            }
            //-- end of Primary Category Item Audit -----------------------------------

            //-- Category Tree --------------------------------------------------------
            function walkTree(node, category, item, opts) {
               // walks the tree down from the given node
               // category: a function to execute if the child node is a category. Is passed the child node and opts object.
               // item: a function to execute if the child node is an item. Is passed the child node and opts object.
               // opts: an object with extra values to pass to the category and item functions.
               if (Array.isArray(node)) {
                  // if the given node is an array, convert it to an object with the expected format
                  node = {
                     nodes: node,
                  }
               }
               if (node.nodes && node.nodes.length) {
                  for (var i = 0; i < node.nodes.length; i++) {
                     var child = node.nodes[i]
                     if (child.isCategory) {
                        category(child, opts)
                        walkTree(child, category, item, opts)
                     } else {
                        item(child, opts)
                     }
                  }
               }
            }

            function walkAllTrees(category, item, opts) {
               walkTree($scope.tree, category, item, opts) // remove the primary category from any items already loaded in the tree.
               walkTree($scope.displayItems, category, item, opts)
               walkTree($scope.displayPage, category, item, opts)
               walkTree($scope.uncategorized, category, item, opts) // it is unlikely, but technically possible.
               walkTree($scope.primaryCategoryAudit, category, item, opts) // it is unlikely, but technically possible.
            }

            $scope.getkids = function (category, force) {
               // fills the given category with it's child nodes.
               // the force parameter is optional and will guarantee that the nodes array is updated from the server if a truthy value is passed.

               if (!category || !category.id) {
                  category = {
                     nodes: $scope.tree,
                     primary: true,
                     active: true,
                  }
               }

               if (force) {
                  // erase the nodes array
                  category.nodes.length = 0
               }

               if (category.nodes && category.nodes.length === 0) {
                  var tempnodes = []
                  return getCategories(category.id)
                     .$promise.then(function (categories) {
                        categories.forEach(function (cat) {
                           tempnodes.push(processCategoryNode(cat, category))
                        })
                        return Promise.resolve()
                     })
                     .then(function () {
                        if (category.id) {
                           return getPackagedProducts(category.id).$promise.then(function (resp) {
                              processPackagedProducts(resp, tempnodes)
                              category.nodes.push.apply(category.nodes, sortNodes(tempnodes))
                              return Promise.resolve()
                           })
                        } else {
                           category.nodes.push.apply(category.nodes, sortNodes(tempnodes))
                           return Promise.resolve()
                        }
                     })
               }
            }

            function getCategories(parentId) {
               var promise = AzureAPI.category.query({
                  parent: parentId || 'null',
                  limit: $scope.productQueryLimit,
               })
               promise.$promise.catch(function (err) {
                  console.log('get categories error: ', err)
                  alert.add('danger', 'There was a problem loading the category.')
               })
               return promise
            }

            function getPackagedProducts(categoryId) {
               var promise = AzureAPI['packaged-product'].query({
                  category: categoryId,
                  limit: $scope.productQueryLimit,
                  tag: '*new-not-on-sale',
                  inline: 'product.brand',
               })
               promise.$promise.catch(function (err) {
                  console.log('get categories error: ', err)
                  alert.add('danger', 'There was a problem loading the packaged-products.')
               })
               return promise
            }

            function processCategoryNode(category, parent) {
               category.canBePrimary = !!parent.primary
               category.canBeActive = !!parent.active
               category.isCategory = true
               category.nodes = []
               category.empty = []
               category.lvl = parent.lvl ? parent.lvl + 1 : 1
               return category
            }

            function sortNodes(nodes) {
               // sort the nodes array alphabetically with the categories above the items
               var cats = []
               var itemNodes = []
               while (!!nodes.length) {
                  var node = nodes.pop()
                  if (node.isCategory) {
                     cats.push(node)
                  } else {
                     itemNodes.push(node)
                  }
               }
               cats.sort(function (a, b) {
                  if (a.name < b.name) {
                     return -1
                  } else {
                     return 1
                  }
               })
               itemNodes.sort(function (a, b) {
                  if (a.name < b.name) {
                     return -1
                  } else {
                     return 1
                  }
               })
               return cats.concat(itemNodes)
            }

            $scope.getkids(null)
            //-- end of Category Tree -------------------------------------------------

            //-- Results Display ------------------------------------------------------
            $scope.sortResults = function (key) {
               // sort the results array by the given key
               if ($scope.resultsSortKey === key) {
                  // the results are already sorted by this key so just reverse the results.
                  $scope.sortAsc = !$scope.sortAsc
                  $scope.displayItems.reverse()
               } else {
                  // the results are not sorted by this key.
                  $scope.resultsSortKey = key
                  $scope.sortAsc = true
                  $scope.displayItems.sort(function (a, b) {
                     if (a[key] < b[key]) {
                        return -1
                     }
                     if (a[key] > b[key]) {
                        return 1
                     }
                     return 0
                  })
               }
               $scope.setPage($scope.currentPage)
            }

            $scope.setPage = function (page) {
               // set the current page for pagination of search results
               $scope.totalPages = Math.ceil($scope.displayItems.length / $scope.pageSize)
               if (page <= $scope.totalPages) {
                  $scope.currentPage = page
                  var start = ($scope.currentPage - 1) * $scope.pageSize
                  var finish = start + $scope.pageSize
                  if (finish > $scope.displayItems.length) {
                     finish = $scope.displayItems.length
                  }
                  $scope.displayPage = $scope.displayItems.slice(start, finish)
               }
            }

            $scope.clearPage = function () {
               $scope.displayItems = []
               $scope.displayPage = []
               $scope.currentPage = 1
               $scope.totalPages = 0
            }

            $scope.searchCategoryTree = function () {
               if (!$scope.categoryTreeUiFilterByCatIdInput || !Number($scope.categoryTreeUiFilterByCatIdInput)) {
                  return
               }

               $scope.isCategoryTreeSearchBusy = true

               return AzureAPI.category
                  .get({
                     id: $scope.categoryTreeUiFilterByCatIdInput,
                     inline: 'ancestors',
                  })
                  .$promise.then(function (results) {
                     if (!results || !results.ancestors) {
                        return
                     }

                     function getTreeEntryFromNodesByCategoryId(nodes, categoryId) {
                        return nodes.find(function (treeEntry) {
                           return treeEntry.id === categoryId
                        })
                     }

                     var ancestorTreeOrderedParentDown = results.ancestors.reverse()
                     var iterationsNeeded = results.ancestors.length - 1

                     function openCategoryNode(node) {
                        node.displayNodes = true
                        if (node.nodes && node.nodes.length === 0) {
                           return Promise.resolve($scope.getkids(node))
                        }
                     }

                     function openAllCategoryAncestors(firstOrLastFetchedCategoryInTree, ancestorIndex) {
                        var categoryInTree = getTreeEntryFromNodesByCategoryId(
                           firstOrLastFetchedCategoryInTree,
                           ancestorTreeOrderedParentDown[ancestorIndex].id,
                        )
                        return Promise.resolve(openCategoryNode(categoryInTree)).then(function () {
                           if (iterationsNeeded > ancestorIndex && categoryInTree.nodes) {
                              return openAllCategoryAncestors(categoryInTree.nodes, ancestorIndex + 1)
                           } else {
                              // Highlight found category
                              // Note: This has to be an integer for the highlight class to be applied.
                              $scope.categoryIdToHighlightInTree = Number($scope.categoryTreeUiFilterByCatIdInput)

                              // Scroll to found category
                              var element = document.getElementById(
                                 'js-categoryTreeNodeId-' + $scope.categoryIdToHighlightInTree,
                              )
                              if (element) {
                                 _categoryTreeScrollContainer.scrollTo({top: element.offsetTop, behavior: 'smooth'})
                              }

                              // Remove the highlight
                              $timeout(function () {
                                 $scope.categoryIdToHighlightInTree = ''
                              }, 5000)
                           }
                        })
                     }

                     return openAllCategoryAncestors($scope.tree, 0)
                  })
                  .finally(function () {
                     $scope.isCategoryTreeSearchBusy = false
                  })
            }

            $scope.search = function () {
               // search for products with the search form
               $scope.searchExecuting = true
               usSpinnerService.spin('spinner-search')
               $scope.clearPage()
               var results = []
               AzureAPI.product
                  .query({
                     search: $scope.searchTerm,
                     limit: $scope.productQueryLimit,
                     tag: '*new-not-on-sale',
                  })
                  .$promise.then(function (products) {
                     products.forEach(function (prod) {
                        prod.packaging.forEach(function (pack) {
                           pack.product = {
                              id: prod.id,
                              name: prod.name,
                              brand: prod.brand,
                           }
                        })
                        processPackagedProducts(prod.packaging, results)
                     })
                     $scope.totalPages = Math.ceil(results.length / $scope.pageSize)
                     $scope.displayItems = results
                     $scope.setPage(1)
                     usSpinnerService.stop('spinner-search')
                     $scope.searchExecuting = false
                  })
            }
            //-- end of Results Display -----------------------------------------------

            //-- Item Setup -----------------------------------------------------------
            function processPackagedProducts(packs, category, uncategorized) {
               for (var i = 0; i < packs.length; i++) {
                  var pack = packs[i]
                  // hide products that should not be displayed
                  if (
                     pack.code.startsWith('BB') ||
                     pack.tags.indexOf('discontinued') > -1 ||
                     pack.tags.indexOf('in-house') > -1
                  ) {
                     continue
                  }

                  if (!items[pack.code]) {
                     // the item has not been retrieved.
                     pack.flags = {}
                     items[pack.code] = pack
                     if (uncategorized) {
                        items[pack.code].categories = []
                     } else {
                        $scope.getParentCategories(items[pack.code])
                     }
                  }

                  if (Array.isArray(category)) {
                     category.push(items[pack.code])
                  } else {
                     category.nodes.push(items[pack.code])
                  }
               }
            }

            $scope.getParentCategories = function (pack) {
               // get the parent categories for the given packaged-product
               pack.categories = []
               AzureAPI.category
                  .query({
                     'packaged-product': pack.code,
                     limit: 250,
                  })
                  .$promise.then(function (categories) {
                     categories.forEach(function (category) {
                        category.ancestors = []
                        loadAncestors(category, category.parent)
                        pack.categories.push(category)
                     })
                  })

               function loadAncestors(category, ancestorId) {
                  var ancestor = getCategory(ancestorId)
                  ancestor.$promise.then(function (ancestor) {
                     category.ancestors.push(ancestor)
                     if (ancestor.parent) {
                        loadAncestors(category, ancestor.parent)
                     }
                  })
               }

               // only cache categories for this batch of items to prevent stale data
               var categoryLocalCache = {}

               function getCategory(categoryId) {
                  var category = categoryLocalCache[categoryId]
                  if (!category) {
                     category = AzureAPI.category.get({
                        id: categoryId,
                     })
                     categoryLocalCache[categoryId] = category
                  }
                  return category
               }
            }
            //-- end of Item Setup ----------------------------------------------------

            //-- Drag and Drop Controllers --------------------------------------------
            $scope.dragControlListeners = {
               accept: function (src, dest) {
                  // prevent categories being moved
                  // prevent items being dropped in category where the item already exists
                  var destobj = getModelValue(dest.$parent)
                  var count = 0
                  if (destobj && destobj.nodes) {
                     for (var i = 0; i < destobj.nodes.length; i++) {
                        if (destobj.nodes[i].code == src.itemScope.modelValue.code) {
                           count += 1
                        }
                     }
                  }
                  return !src.itemScope.modelValue.isCategory && count < 1
               },
               itemMoved: itemMoved,
               orderChanged: function (event) {
                  /* don't do anything */
               },
            }

            function getModelValue(x) {
               if (!x.modelValue && x.$parent) {
                  return getModelValue(x.$parent)
               } else {
                  return x.modelValue
               }
            }

            function itemMoved(e) {
               var destination = e.dest.sortableScope.category // will be undefined if moved onto right
               var source = e.source.sortableScope.category // will be undefined if moved from right
               var item = e.source.itemScope.modelValue

               if (!source || !destination) {
                  // this item is either being dropped onto or moved from the right,
                  // so duplicate the item instead of moving it.
                  // also update the categories.
                  e.source.itemScope.sortableScope.insertItem(e.source.index, item)
                  // strip items from the root tree
                  $scope.tree.forEach(function (node, idx) {
                     if (!node.isCategory) {
                        $scope.tree.splice(idx, 1)
                     }
                  })
               }

               var setAsPrimary = destination ? shouldSetAsPrimary(source, destination, item) : false

               if (destination && source) {
                  // this item is being moved within the left tree.
                  // add the new category and remove the old
                  addCategory(item.code, destination.id, setAsPrimary).then(function (resp) {
                     removeCategory(item.code, source.id).then(function () {
                        updateCategories(item)
                        if (item['primary-category'] == source.id && !setAsPrimary) {
                           // if the item is being moved out of the primary category and not into a primary category
                           setItemPrimaryCategoryEverywhere(item, null)
                        } else if (setAsPrimary) {
                           item['primary-category'] = destination.id
                           setItemPrimaryCategoryEverywhere(item, destination.id)
                        }
                     })
                     destination.nodes = sortNodes(destination.nodes)
                  })
               } else if (destination) {
                  // this item is being added to a category from the right side
                  // only add the destination
                  addCategory(item.code, destination.id, setAsPrimary).then(function () {
                     updateCategories(item)
                     destination.nodes = sortNodes(destination.nodes)
                     if (setAsPrimary) {
                        setItemPrimaryCategoryEverywhere(item, destination.id)
                     }
                  })
               } else {
                  // item was added to the right side from the left
               }
            }

            function shouldSetAsPrimary(source, destination, item) {
               // if the category is a primary category,
               // and the item has a primary category,
               // and the old category is not the item's primary category,
               // then prompt the user to decide which category should be the primary one.
               source = source || {
                  id: null,
               }
               destination = destination || {
                  id: null,
               }
               var setAsPrimary = destination ? destination.primary : false
               if (
                  destination.primary &&
                  item['primary-category'] &&
                  item['primary-category'] != source.id &&
                  item['primary-category'] != destination.id
               ) {
                  // ask the user which category should be the primary one
                  // change this to a modal for better UI
                  setAsPrimary = window.confirm(
                     'Change the Primary Category for ' + item.product.name + ' to ' + destination.name + '?',
                  )
               }
               return setAsPrimary
            }
            //-- end of Drag and Drop Controllers -------------------------------------

            //-- Toggle Primary Category ----------------------------------------------
            $scope.togglePrimaryCategory = function (node) {
               node.primaryPopoverOpen = false
               node.primary = !node.primary
               saveCategory(node)
               if (!node.primary) {
                  // the category being changed to not being a primary category.
                  unsetPrimaryCategoryEverywhere(node.id)
               } else {
                  // the category is being changed to being a primary category.
                  setPrimaryCategoryEverywhere(node.id)
               }
               node.nodes.forEach(function (child) {
                  if (child.isCategory) {
                     child.canBePrimary = node.primary // update whether or not the child can be a primary category
                  }
               })
            }

            function unsetPrimaryCategoryEverywhere(catId) {
               // loop through the items object and unset primary categories
               angular.forEach(items, function (val, key) {
                  if (val['primary-category'] == catId) {
                     unsetPrimaryCategory(val)
                  }
                  if (val.categories && val.categories.length) {
                     for (var i = 0; i < val.categories.length; i++) {
                        if (val.categories[i].id == catId) {
                           val.categories[i].primary = false
                        }
                     }
                  }
               })

               unsetPrimaryCategoryChildren($scope.tree, catId) // remove the primary category from any items already loaded in the tree.
               bulkRemovePrimaryCategory(catId)
            }

            function unsetPrimaryCategoryChildren(node, catId) {
               walkTree(node, catAction, itemAction, {
                  catId: catId,
               })

               function catAction(child, opts) {
                  unsetPrimaryCategoryChildren(child, opts.catId) // continue walking down the tree
                  if (node.id == opts.catId) {
                     // the current node is the category that is being unset as primary
                     if (child.primary) {
                        child.primary = false // the child is a primary category, so remove it's primary designation
                        saveCategory(child).then(function () {
                           unsetPrimaryCategoryEverywhere(child.id) // the child was a primary category, so unset items with this category as a primary category
                        })
                     }
                  }
               } // end of catAction

               function itemAction(child, opts) {} // end of itemAction
            } // end of unsetPrimaryCategoryChildren

            function setPrimaryCategoryEverywhere(catId) {
               angular.forEach(items, function (val, key) {
                  if (val.categories && val.categories.length) {
                     for (var i = 0; i < val.categories.length; i++) {
                        if (val.categories[i].id == catId) {
                           val.categories[i].primary = true
                        }
                     }
                  }
               })
            }

            function bulkRemovePrimaryCategory(id, start) {
               AzureAPI['packaged-product']
                  .query({
                     'primary-category': id,
                     limit: $scope.productQueryLimit,
                     start: start || 0,
                     tag: '*new-not-on-sale',
                  })
                  .$promise.then(function (resp) {
                     if (resp.length == $scope.productQueryLimit) {
                        bulkRemovePrimaryCategory(id, start + $scope.productQueryLimit)
                     }
                     resp.forEach(function (item) {
                        unsetPrimaryCategory(item)
                     })
                  })
            }

            function unsetPrimaryCategory(item) {
               removeCategory(item.code, item['primary-category']).then(function () {
                  addCategory(item.code, item['primary-category'], false).then(function () {
                     item['primary-category'] = null
                  })
               })
            }
            //-- end of Toggle Primary Category ---------------------------------------

            //-- Toggle Inactive Category ---------------------------------------------
            $scope.toggleInactiveCategory = function (node, cascade) {
               node.activePopoverOpen = false
               node.active = !node.active
               saveCategory(node)

               if (node.nodes && node.nodes.length > 0) {
                  // the node has children that need to have their canBeActive value set
                  processChildren(node, node.active, cascade)
               }
               setCategoryActiveValueEverywhere(node.id, node.active)

               function processChildren(obj, val, cascade) {
                  obj.nodes.forEach(function (child) {
                     // loop through the nodes array
                     if (child.isCategory) {
                        // only care about category nodes
                        child.canBeActive = val // set the child node.canBeActive to the value of the parent.active
                        child.active = val // set the active value
                        if (child.nodes && child.nodes.length > 0) {
                           // if the child has child nodes
                           processChildren(child, val, cascade) // the child has child nodes, so continue processing it's child nodes
                        }
                     }
                  })
               }
            }

            function setCategoryActiveValueEverywhere(catId, active) {
               angular.forEach(items, function (val, key) {
                  if (val.categories && val.categories.length) {
                     for (var i = 0; i < val.categories.length; i++) {
                        if (val.categories[i].id == catId) {
                           val.categories[i].active = active
                        }
                     }
                  }
               })
            }
            //-- end of Toggle Inactive Category --------------------------------------

            //-- Category Functions ---------------------------------------------------
            $scope.toggleCategoryNode = function (node) {
               // expand or collapse the node in the category tree.
               if (node.nodes && node.nodes.length === 0) {
                  $scope.getkids(node)
               } else {
                  node.nodes = sortNodes(node.nodes)
               }
               node.displayNodes = !node.displayNodes
            }

            $scope.deleteCategory = function (element, node) {
               var listing = element.$parent.$parent
               listing.sortableScope.removeItem(listing.$index)
               node.$delete()
            }

            $scope.canDeleteCategory = function (element, node) {
               if (!node.deletePopoverOpen) {
                  if (node.nodes && node.nodes.length === 0) {
                     getCategories(node.id).$promise.then(function (resp) {
                        if (resp.length) {
                           canNotDelete(node) // the category has sub-categories and can not be deleted
                        } else {
                           getPackagedProducts(node.id).$promise.then(function (ppResp) {
                              if (resp.length) {
                                 canNotDelete(node) // there are items in this category, so it can not be deleted
                              } else {
                                 canDelete(node) // there are no sub-categories or items, so it can be deleted
                              }
                           })
                        }
                     })
                  } else {
                     canNotDelete(node) // can not delete category
                  }
               } else {
                  node.deletePopoverOpen = false // this is just closing the popover
               }

               function canNotDelete(node) {
                  node.canDelete = false
                  node.deletePopoverOpen = true
               }

               function canDelete(node) {
                  node.canDelete = true
                  node.deletePopoverOpen = true
               }
            }

            function saveCategory(data) {
               if (typeof data.keywords === 'string') {
                  data.keywords = data.keywords.split(/\s*,\s*/)
               } else if (!data.keywords) {
                  data.keywords = []
               }
               return AzureAPI.category.save(data).$promise
            }

            function addCategory(code, categoryId, primaryId) {
               return AzureAPI['packaged-product'].addCategory({
                  code: code,
                  categoryId: categoryId,
                  primary: primaryId,
               }).$promise
            }

            function removeCategory(code, category) {
               return AzureAPI['packaged-product'].removeCategory({
                  code: code,
                  categoryId: category,
               }).$promise
            }

            function updateCategories(item) {
               $scope.getParentCategories(items[item.code])
            }

            $scope.editCategorySuccess = function (cat) {
               walkAllTrees(catAction, itemAction, {
                  cat: cat,
               })

               function catAction(child, opts) {
                  if (child.id == opts.cat.parent) {
                     child.nodes = sortNodes(child.nodes)
                  }
               }

               function itemAction(child, opts) {
                  if (child.categories && child.categories.length) {
                     child.categories.forEach(function (childCat) {
                        renameCat(childCat, opts.cat)
                        childCat.ancestors.forEach(function (childCatAnc) {
                           renameCat(childCatAnc, opts.cat)
                        })
                     })
                  }
               }

               function renameCat(oldCat, newCat) {
                  if (oldCat.id == newCat.id) {
                     oldCat.name = newCat.name
                  }
               }
            }

            $scope.closeEditCategory = function () {
               $scope.categoryToEdit = {}
            }

            $scope.addCategorySuccess = function (resp, parent) {
               if (Array.isArray(parent)) {
                  // parent is an array, so this is a root category
                  parent.push(
                     processCategoryNode(resp, {
                        active: true,
                        primary: true,
                     }),
                  )
               } else {
                  // this is a child node
                  if (resp.active !== parent.active) {
                     // make sure that the proper active value is set
                     resp.active = parent.active
                     saveCategory(resp).then(function () {
                        parent.nodes.push(processCategoryNode(resp, parent))
                        parent.nodes = sortNodes(parent.nodes)
                     })
                  } else {
                     parent.nodes.push(processCategoryNode(resp, parent))
                     parent.nodes = sortNodes(parent.nodes)
                  }
               }
            }

            $scope.closeCreateCategory = function () {
               return
            }
            //-- end of Category Functions --------------------------------------------

            //-- Item Functions -------------------------------------------------------
            $scope.toggleCategories = function (pack, flag) {
               pack.flags[flag] = !pack.flags[flag]
            }

            $scope.productUrl = function (product) {
               return config.website + '/shop/product/' + slug.create(product.name) + '/' + product.id
            }

            $scope.removeItemFromCategory = function (index, node, parent) {
               node.flags[parent.id + 'removePopoverOpen'] = false
               removeCategory(node.code, parent.id).then(function () {
                  if (node['primary-category'] == parent.id) {
                     setItemPrimaryCategoryEverywhere(node, null)
                  }
                  updateCategories(node)
                  parent.nodes.splice(index, 1)
               })
            }

            $scope.removeItemFromResults = function (index) {
               $scope.displayPage.splice(index, 1)
               $scope.displayItems.splice(index + ($scope.currentPage - 1) * $scope.pageSize, 1)
            }

            $scope.setPrimary = function (node, cat) {
               var oldPrimaryCategory = node['primary-category']
               setItemPrimaryCategoryEverywhere(node, cat.id)
               addCategory(node.code, cat.id, true)
                  .then(function () {
                     node['primary-category'] = cat.id
                  })
                  .catch(function (err) {
                     node['primary-category'] = oldPrimaryCategory
                     console.log('setPrimary error: ', err)
                     $window.alert('There was a problem setting the primary category.')
                  })
            }

            function setItemPrimaryCategoryEverywhere(item, cat) {
               items[item.code]['primary-category'] = cat
            }
            //-- end of Item Functions ------------------------------------------------
         },
      )
})()
