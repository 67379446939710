'use strict'

/**
 * @ngdoc function
 * @name internalWebsiteApp.controller:TelephoneCtrl
 * @description
 * # TelephoneCtrl
 * Controller of the internalWebsiteApp
 */
angular.module('internalWebsiteApp').controller('TelephoneCtrl', [
   '$scope',
   '$location',
   '$q',
   '$routeParams',
   'AzureAPI',
   'alert',
   'config',
   function ($scope, $location, $q, $routeParams, AzureAPI, alert, config) {
      $scope.website = config.website

      if ($routeParams['telephone']) {
         $scope.telephone = $routeParams['telephone']
      } else {
         $scope.telephone = null
      }

      var clearStatus = function () {
         $scope.status = null
      }

      clearStatus()

      var getSMSnotifications = function (notifications) {
         var SMSnotifications = []
         for (var key in notifications) {
            if (notifications[key].indexOf('sms') !== -1) {
               SMSnotifications.push(key)
            }
         }
         SMSnotifications.sort()
         return SMSnotifications
      }

      $scope.ok = function () {
         if (!$scope.telephone) {
            $location.search({})
            return
         }
         $location.search({telephone: $scope.telephone})

         $scope.people = []
         $scope.status = 'Looking up people…'
         var addresses = AzureAPI.address.query({
            inline: 'person.email',
            telephone: $scope.telephone,
         })
         addresses.$promise.catch(function (error) {
            alert.catchNetwork('telephone')(error)
            clearStatus()
         })
         addresses.$promise
            .then(function (addresses) {
               var people = {}
               addresses.forEach(function (address) {
                  if (!people[address.person.id]) {
                     people[address.person.id] = {
                        person: address.person,
                        address: address,
                        SMSnotifications: getSMSnotifications(address.person.notifications),
                     }
                  }
               })
               var pids = Object.keys(people)
               pids.sort()
               pids.forEach(function (pid) {
                  $scope.people.push(people[pid])
               })
            })
            .finally(clearStatus)
      }

      $scope.ok()

      $scope.noSMSnotifications = function (entry) {
         $scope.status =
            'Removing ' +
            entry.SMSnotifications.join(', ') +
            ' SMS notifications for ' +
            entry.person.name +
            ' (' +
            entry.person.id +
            ')…'
         var promises = []
         if (entry.SMSnotifications.length > 0) {
            entry.SMSnotifications.forEach(function (event) {
               entry.person.notifications[event] = entry.person.notifications[event].filter(function (channel) {
                  return channel != 'sms'
               })
            })
            var person = AzureAPI.person.save(entry.person)
            person.$promise.catch(function (error) {
               alert.catchNetwork('person')(error)
            })
            promises.push(
               person.$promise.then(function (person) {
                  person.email = entry.person.email // currently no ?inline support for PUT /person/{id}
                  entry.person = person
                  entry.SMSnotifications = getSMSnotifications(entry.person.notifications)
               }),
            )
         }
         var memberships = AzureAPI['drop-membership'].query({
            'filter-person': entry.person.id,
            limit: 250,
         })
         memberships.$promise.catch(function (error) {
            alert.catchNetwork('drop-membership')(error)
         })
         promises.push(
            memberships.$promise.then(function (memberships) {
               var memberPromises = []
               memberships.forEach(function (membership) {
                  var SMSnotifications = getSMSnotifications(membership.notifications)
                  if (SMSnotifications.length > 0) {
                     SMSnotifications.forEach(function (event) {
                        membership.notifications[event] = membership.notifications[event].filter(function (channel) {
                           return channel != 'sms'
                        })
                     })
                     var member = AzureAPI['drop-membership'].save(membership)
                     member.$promise.catch(function (error) {
                        alert.catchNetwork('drop-membership')(error)
                     })
                     memberPromises.push(person.$promise)
                  }
               })
               return $q.all(memberPromises)
            }),
         )
         $q.all(promises)
            .then(function () {
               alert.add(
                  'success',
                  'Removed SMS notifications for ' + entry.person.name + ' (' + entry.person.id + ').',
               )
            })
            .catch(function (error) {
               alert.add('warning', error)
            })
            .finally(clearStatus)
      }
   },
])
