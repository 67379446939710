;(function () {
   'use strict'

   angular.module('internalWebsiteApp').controller('UpdateRouteCtrl', updateRouteController)

   function updateRouteController($rootScope, $scope, $uibModal, logisticsData, alertService, util) {
      var _logisticsRootState = $scope.logisticsRootState
      var _routeUpdateModalState = {
         busy: false,
         formState: {},
      }
      $scope.routeUpdateModalState = _routeUpdateModalState

      $scope.openRouteUpdateModal = function () {
         var formAttrs = [
            'name',
            'internal',
            'hub',
            'description',
            'cutoff-frequency',
            'delivery-offset',
            'backhaul-cost-per-pound',
            'backhaul-cost-per-pickup',
            'driverName',
            'notes',
         ]

         formAttrs.forEach(function (attr) {
            _routeUpdateModalState.formState[attr] = angular.copy(_logisticsRootState.route[attr])
         })

         _routeUpdateModalState.formState.driverPhoneUnformatted = util.stripCountryFromPhone(
            _logisticsRootState.route.driverPhone,
         )

         $scope.$uibModalInstance = $uibModal.open({
            scope: $scope,
            templateUrl: 'modules/logistics/views/updateRouteModal.html',
            size: '',
         })
      }

      $scope.cancel = function () {
         $scope.$uibModalInstance.dismiss('cancel')
      }

      $scope.submit = function () {
         $rootScope.blur()
         _routeUpdateModalState.busy = true

         var updatedRouteInfo = angular.extend({}, _routeUpdateModalState.formState)
         if (updatedRouteInfo.driverPhoneUnformatted) {
            var phoneFormatted = util.cleanPhone(updatedRouteInfo.driverPhoneUnformatted)
            updatedRouteInfo.driverPhone = util.addCountryToPhone(phoneFormatted)
         } else {
            updatedRouteInfo.driverPhone = ''
         }
         delete updatedRouteInfo.driverPhoneUnformatted

         // Format the description
         updatedRouteInfo.description = _logisticsRootState.formatRouteDescription(updatedRouteInfo.description)

         updateRoute(updatedRouteInfo)
      }

      function updateRoute(route) {
         return logisticsData
            .updateRoute(route)
            .then(util.cleanItem)
            .then(function (updatedRoute) {
               // Replace the stored-in-memory route with the updated route from the response
               var existingRoute = util.findById(_logisticsRootState.routesAll, updatedRoute.id)
               angular.extend(existingRoute, updatedRoute)

               // Close the edit modal
               $scope.$uibModalInstance.close()
            })
            .then(function () {
               alertService.successMessage('Route updated')
            })
            .catch(function (error) {
               console.error(error)
               alertService.errorMessage('Error updating the route. Error logged to console.')
            })
            .finally(function () {
               _routeUpdateModalState.busy = false
            })
      }
   }
})()
